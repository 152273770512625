import segment from './segment';
import sessionCam from './session-cam';
import appsFlyer from './apps-flyer';
import datadog from './datadog';

export default {
    segment,
    sessionCam,
    appsFlyer,
    datadog,
};
