import './dotenv';

import { setEntry } from '@clearscore-group/lib.helpers.cache';
import { GB, EN_GB, LOCALES, MARKETS } from '@clearscore/config.i18n';

import { getFeatureFlags } from './get-feature-flags';
import clientEnvVars from './client-env-vars';

interface CSWebsiteConfig {
    NODE_ENV: string;
    PORT: string | number;
    ADANA_API_KEY?: string;
    ADANA: {
        api: string;
        apiKey: string;
    };
    release: {
        app: string;
        release: string;
        env: string;
    };
    featureFlags: Record<string, boolean>;
    MARKET: string;
    DEFAULT_LOCALE: string;
    MARKETS: readonly string[];
    LOCALES: readonly string[];
    LOCALE: string;
    SEGMENT_ID: string;
    APPS_FLYER_ID: string;
    SESSIONCAM_ACTIVE: string;
    COOKIE_DOMAIN: string;
    WEBAPP_URL: string;
    WEBAPI_URL: string;
    INTERNATIONAL_API_DOMAIN: string;
    MARKETING_REDIRECT: string;
    HOMEPAGE_URL: string;
    ENVIRONMENT: string;
    DEBUG?: boolean;
    PERIMETERX_APP_SRC: string;
    ADDRESS_SERVICE_KEY: string;
}

// eslint-disable-next-line import/no-mutable-exports
let config: Partial<CSWebsiteConfig> = {};
const resolveKey = <T, R = unknown>(target: T, keyPath: string): R =>
    keyPath.split('.').reduce((previous, current) => previous[current], target);

const mandatoryConfig = ['ADANA.api', 'ADANA.apiKey'];

const defaultLocale = process.env.DEFAULT_LOCALE || LOCALES[0];
const market = process.env.MARKET || MARKETS[0];
const NODE_ENV = process.env.NODE_ENV || 'production';
const PORT = process.env.PORT || 8082;
const CMS_API_HOST = process.env.CMS_API_HOST || 'https://admin.gb-ci.eu-west-2.clearscore.io/global-website-cms';
const fallbackSegmentId =
    market === GB
        ? /* legacy uk segment */ 'a9L6mxpUYJoRKzCbXPe6yk26ug3Y9y0F'
        : /* new global segment */ 'ZvtC31nqOaIOf7NxJLqTHc4r07tpSpws';
const SEGMENT_ID = process.env.SEGMENT_ID || fallbackSegmentId;
const { ADDRESS_SERVICE_KEY } = process.env;
const { SESSIONCAM_ACTIVE, ADANA_API_KEY } = process.env;
const WEBAPP_URL = process.env.WEBAPP_URL || 'https://app.clearscore.com';
const WEBAPI_URL = process.env.WEBAPI_URL || 'https://app.clearscore.com/api';
const HOMEPAGE_URL = process.env.HOMEPAGE_URL || `https://www.clearscore.com/${market === GB ? '' : market}`;
const INTERNATIONAL_API_DOMAIN = process.env.INTERNATIONAL_API_DOMAIN || 'https://app.clearscore.com/api/@market@';
const { MARKETING_REDIRECT = '' } = process.env;

const site = process.env.SITE || 'clearscore';
const cookieDomain = {
    clearscore: process.env.COOKIE_DOMAIN,
    drivescore: '.drivescore.com',
};

const setConfig = (): void => {
    // set prod / default env here
    config = {
        NODE_ENV,
        PORT,
        ADANA_API_KEY,
        ADANA: {
            api: CMS_API_HOST,
            apiKey: ADANA_API_KEY,
        },
        release: {
            app: process.env.APP,
            release: process.env.RELEASE,
            env: process.env.ENV,
        },
        // automatically add feature-flags ready to be used by server-side code if needed
        featureFlags: getFeatureFlags(),
        MARKET: market,
        DEFAULT_LOCALE: defaultLocale,
        MARKETS,
        LOCALES,
        LOCALE: LOCALES.find((locale) => locale.endsWith(market)) ?? EN_GB,
        SEGMENT_ID,
        APPS_FLYER_ID: process.env.APPS_FLYER_ID,
        SESSIONCAM_ACTIVE,
        COOKIE_DOMAIN: cookieDomain[site],
        WEBAPP_URL,
        WEBAPI_URL,
        INTERNATIONAL_API_DOMAIN,
        MARKETING_REDIRECT,
        HOMEPAGE_URL,
        ENVIRONMENT: process.env.ENV,
        ADDRESS_SERVICE_KEY,
        ...clientEnvVars(),
    };

    // needed for some components that use `getEnvs()`
    setEntry('env', {
        COOKIE_DOMAIN: cookieDomain[site],
        WEBAPP_URL,
        WEBAPI_URL,
        INTERNATIONAL_API_DOMAIN,
        ADDRESS_SERVICE_KEY,
        ENABLE_LEARN: process.env.FEATURE_FLAG__ENABLE_LEARN,
        ENABLE_PROTECT: process.env.FEATURE_FLAG__ENABLE_PROTECT,
        ENABLE_PROTECT_PAID: process.env.FEATURE_FLAG__ENABLE_PROTECT_PAID,
        TRACKING_EVENT_RECEIVER_ENABLED: process.env.FEATURE_FLAG__ENABLE_TRACKING_EVENT_RECEIVER,
        MARKET: process.env.MARKET,
        SITE: process.env.SITE,
        ...clientEnvVars(),
    });

    setEntry('release', {
        env: process.env.ENV === 'development' ? undefined : 'prod',
    });

    // explicitly check vars so that webpack can help us
    if (config.NODE_ENV === 'development') {
        // set dev envs here
        config.DEBUG = false;
    }
};

if (Object.keys(config).length === 0) {
    setConfig();
}

const missingConfig = mandatoryConfig.filter((key) => typeof resolveKey(config, key) === 'undefined');
const hasWindow = typeof window !== 'undefined';

if (!hasWindow) {
    // eslint-disable-next-line no-console
    console.log(`
    =========================
        MARKET:         ${market}
        FEATURE FLAGS:
    ${Object.keys(config.featureFlags)
        .map((feature) => `${feature}: ${config.featureFlags[feature]}`)
        .join('\n')}

    =========================
    `);
}

if (missingConfig.length && !hasWindow) {
    // eslint-disable-next-line no-console
    console.log({ missingConfig });
}

export default config;
