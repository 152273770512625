// config *must* run before everything else
/* eslint-disable */
import config from '../config/config';
/* eslint-enable */
import React from 'react';
import { LOCALES } from '@clearscore/config.i18n';
import CSProvider from '@clearscore-group/lib.redux.cs-provider';
import signupEpic from '@clearscore-group/lib.epic.website-signup';
import { reducers as market } from '@clearscore-group/lib.redux.market';
import { reducers as configReducer } from '@clearscore-group/lib.redux.config';
import { reducers as cookiePolicy } from '@clearscore-group/lib.redux.cookie-policy';
import { reducers as prospect } from '@clearscore-group/lib.redux.prospect';
import requestMiddleware from '@clearscore/lib.website-core.request-middleware';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import loadThirdPartyScripts from '../lib/third-party';

const locale = config.DEFAULT_LOCALE;
const storeConfig = {
    name: 'core',
    pageEpics: { signupEpic },
    reducers: {
        market: market.domain,
        prospect: prospect.domain,
        config: configReducer.configReducer,
        cookiePolicy: cookiePolicy.domain,
    },
    pageMiddleWare: [requestMiddleware],
    initialState: {
        market: {
            activeMarket: config.MARKET,
            activeLocale: `en-${config.MARKET}`,
            locales: LOCALES,
        },
    },
};

const queryClient = new QueryClient();

const ProviderWrapper = ({ element }: { element: React.ReactNode }): React.ReactElement => (
    <QueryClientProvider client={queryClient}>
        <CSProvider defaultLocale={locale} defaultMarket={config.MARKET.toLowerCase()} storeConfig={storeConfig}>
            {(): React.ReactNode => {
                loadThirdPartyScripts({
                    SEGMENT_ID: config.SEGMENT_ID || 'demo',
                    APPS_FLYER_ID: config.APPS_FLYER_ID,
                    SESSIONCAM_ACTIVE: config.SESSIONCAM_ACTIVE,
                    market: config.MARKET,
                });

                return element;
            }}
        </CSProvider>
    </QueryClientProvider>
);

export default ProviderWrapper;
