import type { RumEvent, RumInitConfiguration } from '@datadog/browser-rum';
import type { LogsInitConfiguration } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { getMarket } from '@clearscore-group/lib.helpers.market';
import { getPersonCookie, getCookie, constants } from '@clearscore-group/lib.helpers.cookies';
import type { Market } from '@clearscore-group/lib.config.i18n';

export const ddApps = {
    webapp: 'clearscore-webapp',
    drivescoreWebapp: 'drivescore-webapp',
    drivescoreWebsite: 'drivescore-website',
    groupWebsite: 'group-website',
    website: 'clearscore-website',
} as const;

type DDAppKey = keyof typeof ddApps;

export const ddApplicationIds: Record<DDAppKey, string> = {
    webapp: '3eb18c69-38e0-4095-80e2-78aab47831ae',
    drivescoreWebapp: '4f347bd3-31d2-41c0-a304-4b542321bfaa',
    drivescoreWebsite: '97a55d2a-3577-4acd-8cde-b7b4a2eb6f8c',
    groupWebsite: '6031bc1e-2a87-4fbc-8325-35107239f390',
    website: 'test-website',
} as const;

export const ddClientTokens: Record<DDAppKey, string> = {
    webapp: 'pub29a684674f4826c6737542cce2219201',
    drivescoreWebapp: 'pub8b95eb1a276fc5e5d1e4d5a928d49b2b',
    drivescoreWebsite: 'pubf94cd5795b86d1abd74dbbaae949ac7b',
    groupWebsite: 'pubf4b21d99bf416a68ede721806887dea0',
    website: 'pubce82de9f9e4cbf8ee2f2a8ba0db399bc',
} as const;

type DDAppValue = (typeof ddApps)[DDAppKey];
type DDApplicationIdValue = (typeof ddApplicationIds)[DDAppKey];
type DDClientToken = (typeof ddClientTokens)[DDAppKey];
type DDEnvironment = 'ci' | 'staging' | 'production';
type DDInstance = typeof datadogRum | typeof datadogLogs;

export const DD_DEFAULT_SESSION_SAMPLE_RATE = 1;
const DD_DEFAULT_SESSION_REPLAY_SAMPLE_RATE = 0;
const DD_DEFAULT_TRACE_SAMPLE_RATE = 10;
const DD_SITE = 'datadoghq.eu';
const DD_DEFAULT_PRIVACY_LEVEL = 'mask';

const environmentMap: Record<string, DDEnvironment> = {
    ci: 'ci',
    stg: 'staging',
    prod: 'production',
} as const;

const uuidFunctionMap: Record<DDAppKey, () => string> = {
    webapp: getPersonCookie,
    drivescoreWebapp: getPersonCookie,
    drivescoreWebsite: getPersonCookie,
    website: getPersonCookie,
    groupWebsite: getPersonCookie,
} as const;

const getUuid = (clientToken: string) => {
    const app = Object.keys(ddClientTokens).find((key) => ddClientTokens[key as DDAppKey] === clientToken);
    return uuidFunctionMap[app as DDAppKey]();
};

const setUser = (clientToken: string, dataDogInstance: DDInstance) => {
    let uuid;
    try {
        uuid = getUuid(clientToken);
        if (typeof uuid === 'string') {
            dataDogInstance.setUser({ id: uuid });
        } else {
            throw new Error('Invalid UUID');
        }
    } catch (e) {
        // Don't set uuid.
    }
};

function getRelease() {
    if (window.__cs__?.release?.env) {
        // Standard Applications
        return environmentMap[window.__cs__.release.env];
    }

    if (window.process?.env?.ENV) {
        // Remix Applications
        return window.process.env.ENV as DDEnvironment;
    }

    return undefined;
}

function getMappedEnv(market: Market | null) {
    if (!market) {
        return null;
    }
    return `${market}-${getRelease()}`;
}

function calculateMarket() {
    let market = getMarket();

    if (!market && typeof window !== 'undefined' && window.process?.env?.MARKET) {
        market = window.process.env.MARKET as Market;
    }

    if (market === undefined) {
        // Fallback to the browser locale from the i18next-browser-languagedetector language detector
        // (packages/clearscore-group/libs/redux/cs-provider/src/lib/i18n.ts)
        // as a best guess for market on the login screen.
        const currentLanguage = getCookie(constants.CS_CURRENT_LANGUAGE);

        if (currentLanguage === undefined) {
            return null;
        }

        // We currently only have `en-${market}` languages; if this ever changes, update this.
        [, market] = currentLanguage.split('-');
    }

    return market || null;
}

type ErrorMessage = string;
type Reason = string;

export const errorMessagesToFilter: Record<ErrorMessage, Reason> = {
    ResizeObserver: 'not polyfilled',
};

export const beforeSend = (event: RumEvent) => {
    if (event.error && event.type === 'error') {
        return !Object.keys(errorMessagesToFilter).some((errorMessage) => event.error.message.includes(errorMessage));
    }
    return true;
};

export const initialiseDataDogRUM = (
    app: DDAppValue,
    applicationId: DDApplicationIdValue,
    clientToken: DDClientToken,
    sessionSampleRate: RumInitConfiguration['traceSampleRate'] = DD_DEFAULT_SESSION_SAMPLE_RATE,
) => {
    if (typeof window === 'undefined') {
        return;
    }

    if (!getRelease()) {
        return;
    }

    const market = calculateMarket();
    const mappedEnv = getMappedEnv(market);

    // Temporary React 19 testing
    if (
        (mappedEnv?.endsWith('ci') || mappedEnv?.endsWith('staging')) &&
        window.__cs__?.vendor?.React?.version === '19.0.0'
    ) {
        // eslint-disable-next-line no-param-reassign
        sessionSampleRate = 100;
    }

    if (mappedEnv && !window.DD_RUM?.getInternalContext()) {
        datadogRum.init({
            applicationId,
            clientToken,
            site: DD_SITE,
            service: app,
            env: mappedEnv,
            version: window.__cs__?.release?.core,
            sessionSampleRate,
            sessionReplaySampleRate: DD_DEFAULT_SESSION_REPLAY_SAMPLE_RATE,
            traceSampleRate: DD_DEFAULT_TRACE_SAMPLE_RATE,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: DD_DEFAULT_PRIVACY_LEVEL,
            beforeSend,
        });
        setUser(clientToken, datadogRum);
    }
};

export const initialiseDataDogLogs = (
    clientToken: DDClientToken,
    sessionSampleRate: LogsInitConfiguration['sessionSampleRate'] = DD_DEFAULT_SESSION_SAMPLE_RATE,
) => {
    if (typeof window === 'undefined') {
        return;
    }

    if (!getRelease()) {
        return;
    }

    const market = calculateMarket();
    const mappedEnv = getMappedEnv(market);

    if (mappedEnv && !window.DD_LOGS?.getInternalContext()) {
        datadogLogs.init({
            clientToken,
            site: DD_SITE,
            forwardErrorsToLogs: true,
            sessionSampleRate,
            usePciIntake: true,
            sendLogsAfterSessionExpiration: false,
            env: mappedEnv,
        });
        setUser(clientToken, datadogLogs);
    }
};
