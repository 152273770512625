import { AU, GB, ZA, CA, NZ } from '@clearscore-group/lib.config.i18n';

function formatAddressParts(addressParts) {
    return addressParts.filter(Boolean).join(', ');
}

const isBuildingNumberWithinBuildingName = (buildingNumber, buildingName) => {
    if (!buildingName) return false;

    const regex = /(\d+)/g;
    const numbersInName = buildingName.match(regex);

    if (!numbersInName) return false;

    const isNumberInName = numbersInName.find((numberInName) => numberInName === buildingNumber);

    return !!isNumberInName;
};

export function formatAddress(address, market) {
    const flatOrAbodeNumber = address.flatNumber || address.abodeNumber;
    let addressLine1;
    if (flatOrAbodeNumber) {
        if (isBuildingNumberWithinBuildingName(address.buildingNumber, address.buildingName)) {
            addressLine1 = formatAddressParts([flatOrAbodeNumber, address.buildingName]);
        } else {
            addressLine1 = formatAddressParts([flatOrAbodeNumber, address.buildingName, address.buildingNumber]);
        }
    } else if (isBuildingNumberWithinBuildingName(address.buildingNumber, address.buildingName)) {
        addressLine1 = formatAddressParts([address.buildingName]);
    } else {
        addressLine1 = formatAddressParts([address.buildingNumber, address.buildingName]);
    }
    const streetOrRoad = address.street || address.road;
    const townOrCity = address.town || address.city;
    const countyOrState = address.county || address.state;
    const postcodeOrPincode = address.postcode || address.pinCode;

    switch (market) {
        case AU: {
            const noCommasParts = [address.city, address.state, address.postalCode || address.postcode]
                .filter(Boolean)
                .join(' ');
            if (address.flatUnitNumber || address.streetNumber || address.street) {
                return formatAddressParts([
                    address.flatUnitNumber,
                    address.streetNumber,
                    address.street,
                    noCommasParts,
                ]);
            }
            return formatAddressParts([address.addressLine1, address.addressLine2, noCommasParts]);
        }
        case NZ: {
            const noCommasParts = [address.city, address.state, address.postalCode || address.postcode]
                .filter(Boolean)
                .join(' ');
            if (address.flatNumber || address.buildingNumber || address.street) {
                return formatAddressParts([address.flatNumber, address.buildingNumber, address.street, noCommasParts]);
            }
            return formatAddressParts([address.addressLine1, address.addressLine2, noCommasParts]);
        }

        case ZA:
            return formatAddressParts([
                address.flatNumber,
                address.buildingNumber,
                address.buildingName,
                streetOrRoad,
                townOrCity,
                countyOrState,
                postcodeOrPincode,
            ]);

        case CA:
            return formatAddressParts([
                address.addressLine2,
                address.flatNumber,
                address.buildingNumber,
                address.addressLine1,
                streetOrRoad,
                townOrCity,
                countyOrState,
                address.zipCode,
                postcodeOrPincode,
            ]);
        case GB:
        default:
            return formatAddressParts([addressLine1, streetOrRoad, townOrCity, countyOrState, postcodeOrPincode]);
    }
}

export default function normalizeAddress(value) {
    if (!value) {
        return value;
    }

    const regex = /[A-Za-z0-9'.\-\s'/,]*/g;
    return value.match(regex).join('');
}
