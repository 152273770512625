import canUseDom from '@clearscore-group/lib.helpers.can-use-dom';

import libraries from '.';

const thirdParties = [
    {
        name: 'segment',
        library: libraries.segment,
        loaded: false,
        ssr: false,
        required: 'SEGMENT_ID',
    },
    {
        name: 'appsFlyer',
        library: libraries.appsFlyer,
        loaded: false,
        ssr: false,
        required: 'APPS_FLYER_ID',
    },
    {
        name: 'sessionCam',
        library: libraries.sessionCam,
        loaded: false,
        ssr: false,
        required: 'SESSIONCAM_ACTIVE',
    },
    {
        name: 'datadog',
        library: libraries.datadog,
        loaded: false,
        ssr: false,
    },
];

const loadThirdPartyScripts = (props, callback) => {
    thirdParties.forEach(({ library, loaded, ssr, required }, i) => {
        if (loaded) return;
        if (ssr || (canUseDom() && (!required || props?.[required]))) {
            library(props, callback);
            thirdParties[i].loaded = true;
        }
    });
};

export default loadThirdPartyScripts;
