import profileGetSimple from './profile.getSimple';
import profileGetPredicate from './profile.getPredicate';
import profileGetStatus from './profile.getStatus';
import profileGetTerms from './profile.getTerms';
import profileGetMarketing from './profile.getMarketing';
import profileGetFinancial from './profile.getFinancial';
import profileGetProducts from './profile.getProducts';
import profileGetAdditionalQuestionData from './profile.getAdditionalQuestionData';
import profileGetRemortgageData from './profile.getRemortgageData';
import profileGetReportAddresses from './profile.getReportAddresses';
import profileGetDocumentsMeta from './profile.getDocumentsMeta';
import profileGetSaveModified from './profile.getSaveModified';
import profileIsTwoFactorAuthEnabled from './profile.isTwoFactorAuthEnabled';
import profileGetSelfieRequired from './profile.getSelfieRequired';
import profileGetUserSettings from './profile.getUserSettings';

export const getSimple = profileGetSimple;
export const getPredicate = profileGetPredicate;
export const getStatus = profileGetStatus;
export const getTerms = profileGetTerms;
export const getMarketing = profileGetMarketing;
export const getFinancial = profileGetFinancial;
export const getProducts = profileGetProducts;
export const getAdditionalQuestionData = profileGetAdditionalQuestionData;
export const getRemortgageData = profileGetRemortgageData;
export const getReportAddresses = profileGetReportAddresses;
export const getDocumentsMeta = profileGetDocumentsMeta;
export const getSaveModified = profileGetSaveModified;
export const isTwoFactorAuthEnabled = profileIsTwoFactorAuthEnabled;
export const getSelfieRequired = profileGetSelfieRequired;
export const getUserSettings = profileGetUserSettings;
