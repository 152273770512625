import { MARKETS, LOCALES } from '@clearscore-group/lib.config.i18n';
import * as requestStatuses from '@clearscore-group/lib.constants.request-statuses';

import { getActiveLocale, getActiveMarket } from '../lib/helpers';
import {
    MARKET_SET_LOCALES,
    MARKET_SET_MARKETS,
    MARKET_SET_ACTIVE_LOCALE,
    MARKET_SET_ACTIVE_MARKET,
    MARKET_FETCH_CONFIG,
    MARKET_FETCH_CONFIG_PENDING,
    MARKET_FETCH_CONFIG_ERROR,
    MARKET_FETCH_CONFIG_SUCCESS,
} from '../types';

const initialState = () => ({
    checkStatus: requestStatuses.INITIAL,
    fetchStatus: requestStatuses.INITIAL,
    activeMarket: getActiveMarket(),
    activeLocale: getActiveLocale(),
    markets: MARKETS,
    locales: LOCALES,
    fluxRoutes: [],
    fluxEnvs: {},
});

const domain = (state = initialState(), action = {}) => {
    const { type, payload } = action;

    switch (type) {
        case MARKET_FETCH_CONFIG:
        case MARKET_FETCH_CONFIG_PENDING: // allow core to be aware of pending updates, without re-fetching the config
            return {
                ...state,
                fetchStatus: requestStatuses.PENDING,
            };
        case MARKET_FETCH_CONFIG_SUCCESS:
            return {
                ...state,
                fluxApp: payload?.app || '',
                fluxRoutes: payload?.routes || [],
                fluxSegementation: payload?.segmentation,
                fluxEnvs: payload?.envs || {},
                fetchStatus: requestStatuses.SUCCESS,
            };
        case MARKET_FETCH_CONFIG_ERROR:
            return {
                ...state,
                fetchStatus: requestStatuses.ERROR,
            };
        case MARKET_SET_ACTIVE_MARKET: {
            return {
                ...state,
                activeMarket: (payload && payload.market) || initialState().activeMarket,
                activeLocale: (payload && `en-${payload.market}`) || initialState().activeLocale,
            };
        }
        case MARKET_SET_ACTIVE_LOCALE:
            return {
                ...state,
                activeLocale: payload || initialState().activeLocale,
            };
        case MARKET_SET_LOCALES:
            return {
                ...state,
                locales: payload || initialState().locales,
            };
        case MARKET_SET_MARKETS:
            return {
                ...state,
                markets: payload || initialState().markets,
            };
        default:
            return state;
    }
};

export default domain;
